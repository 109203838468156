// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {ExpandMore, Settings} from '@mui/icons-material';
import {change, Field, getFormValues, reduxForm} from 'redux-form';
import {differenceBy, filter, get, reject, some, union} from 'lodash';
import {Accordion, AccordionDetails, AccordionSummary, Paper, Typography} from '@mui/material';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import MdButton, {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import MdSwitch from '../../../components/md-switch';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import MdDatePicker from '../../../components/md-date-picker';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {ENTITY_TYPES, NEW_ID, ROLES} from '../../../constants/spec';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {USERS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {useParams} from "react-router-dom";
import moment from "moment";
import InputImage from "../../../components/input-image";
import {LIST} from '../../cyber-roles/actions';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

/**
 * check user has 'vendor' roles
 */
let hasVendorRoles = roles => some(roles, role => role.name === ROLES.VENDOR || role.name === ROLES.VNDSUP);

export const changeField = (field, value) => change('editUser', field, value);

/**
 * normalize 'roles' field
 */
let normalizeRoles = (values, prevValues) => {
    let diff1 = differenceBy(values, prevValues, 'id');
    let diff2 = differenceBy(prevValues, values, 'id');
    let updated = union(diff1, diff2), newValues = values || prevValues;
    // NOTE remove other roles if 'vendor' roles has selected
    if (hasVendorRoles(updated)) {
        newValues = filter(newValues, role => role.name === ROLES.VENDOR || role.name === ROLES.VNDSUP);
    }
    // NOTE remove 'vendor' roles if 'common' roles has selected
    if (hasVendorRoles(values) && !hasVendorRoles(updated)) {
        newValues = reject(newValues, role => role.name === ROLES.VENDOR || role.name === ROLES.VNDSUP);
    }
    return newValues;
};

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ USERS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:8,offset:2}}>
                            <Paper className="indent-5">
                                <h2 className="text-">
                                    <span>
                                        <RichHintTitle
                                            update={LIST}
                                            name={isNew ? translate('USERS$CREATE_USER') : translate('USERS$EDIT_USER')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints, isNew ? translate('USERS_CREATE_TITLE') : translate('USERS_EDIT_TITLE'))}
                                        />
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                                </h2>
                                <ConnectedError />
                                <ConnectedForm isNew={isNew} />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
}

export default connect(
    state => ({expectAnswer: state.users.edit.expectAnswer, hints: state.users.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.users.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.users.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.users.edit.data,
        hints: state.users.edit.hintsData,
        disabled: state.users.edit.expectAnswer,
        formValues: getFormValues('editUser')(state)
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        resetPassword: () => dispatch({type: EDIT.RESET_PASSWORD}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editUser',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        let now = new Date();
        // first name
        if (!values.firstName) {
            errors.firstName = 'GLOBALS$NAME_REQUIRED';
        }
        if (!values.lastName) {
            errors.lastName = 'GLOBALS$LAST_NAME_REQUIRED';
        }
        if (!values.mobilePhone) {
            errors.mobilePhone = 'GLOBALS$MOBILE_PHONE_REQUIRED';
        } else if (values.mobilePhone.length > 24) {
            errors.mobilePhone = 'VALIDATION$USER$MOBILE_PHONE$MAX_LENGTH';
        }
        /*
        if (!values.businessUnit) {
            errors.businessUnit = 'BUSINESS_UNITS$BUSINESS_UNIT_PATH_REQUIRED';
        }
        */
        // email
        if (!values.email) {
            errors.email = 'USERS$EMAIL_REQUIRED';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.email)) {
            errors.email = 'USERS$EMAIL_INVALID';
        }
        // roles
        if (!values.roles || !values.roles.length) {
            errors.roles = 'USERS$ROLES_REQUIRED';
        }
        if (values.expirationDate) {
            let expirationDate = moment(values.expirationDate);
            if (expirationDate.isValid()) {
                if (expirationDate.isBefore(now)) {
                    errors.expirationDate = 'VALIDATION$USER$EXPIRATION_DATE$IN_PAST';
                }
            } else {
                errors.expirationDate = 'VALIDATION$INVALID_DATE';
            }
        }
        if (values.credentialsExpirationDate) {
            let credentialsExpirationDate = moment(values.credentialsExpirationDate);
            if (credentialsExpirationDate.isValid()) {
                if (credentialsExpirationDate.isBefore(now)) {
                    errors.credentialsExpirationDate = 'VALIDATION$USER$CREDENTIALS_EXPIRATION_DATE$IN_PAST';
                }
            } else {
                errors.credentialsExpirationDate = 'VALIDATION$INVALID_DATE';
            }
        }
        return errors;
    }
})(({handleSubmit, hints, invalid, pristine, disabled, update, reset, isNew, resetPassword, cancel, formValues, change, changeProfilePicture}) => {
    let isVendorUser = hasVendorRoles(get(formValues, 'roles', []));
    return (<form autoComplete="off" name="editUser" onSubmit={handleSubmit(update)}>
        <Row>
            <Col sm={3} className="user-profile-logo">
                <Row className="offset-top-8">
                    <Col>
                        <Field
                            required
                            name="profilePicture"
                            placeholder="Profile Picture"
                            url={formValues && formValues.profilePicture ? formValues.profilePicture.url : ""}
                            component={InputImage}
                            className="form-control"
                            maxWeight={2}
                            processValue={(uploadResult) => {
                                return uploadResult.logoDocument;
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm={9}>
                <Row>
                    <Col xs={12} md={6} className="offset-bottom-4">
                        <Field
                            name="firstName"
                            component={MdInput}
                            disabled={disabled}
                            placeholder={translate('USERS$FIRST_NAME')}
                            required={true}
                            label={(<strong className="required-asterisk"> {translate('USERS$FIRST_NAME')} </strong>)}
                        />
                    </Col>
                    <Col xs={12} md={6} className="offset-bottom-4">
                        <Field
                            name="lastName"
                            component={MdInput}
                            disabled={disabled}
                            placeholder={translate('USERS$LAST_NAME')}
                            required={true}
                            label={(<strong className="required-asterisk"> {translate('USERS$LAST_NAME')} </strong>)}
                        />
                    </Col>
                    <Col xs={12} className="offset-bottom-4">
                        <Field
                            name="email"
                            component={MdInput}
                            disabled={disabled}
                            placeholder={translate('USERS$EMAIL')}
                            required={true}
                            label={(<strong className="required-asterisk"> {translate('USERS$EMAIL')} </strong>)}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="tel"
                    name="corporatePhone"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('USERS$CORPORATE_PHONE')}
                    label={(<strong> {translate('USERS$CORPORATE_PHONE')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="tel"
                    name="mobilePhone"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('USERS$MOBILE_PHONE')}
                    label={(<strong className="required-asterisk"> {translate('USERS$MOBILE_PHONE')} </strong>)}
                    required={true}
                    maxLength={5}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    name="roles"
                    isMulti={true}
                    disabled={disabled}
                    type={ENTITY_TYPES.ROLES}
                    normalize={normalizeRoles}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                     required={true}
                    label={(<strong className="required-asterisk"> {translate('USERS$ROLES')} </strong>)}
                    onChange={(e, roles)=>!hasVendorRoles(roles)&&change('vendors', [])}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="title"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('USERS$TITLE_FIELD')}
                    label={(<strong> {translate('USERS$TITLE_FIELD')} </strong>)}
                        />
            </Col>
        </Row>
        {isVendorUser && (<Row className="offset-bottom-4"><Col xs={12}>
            <SelectEntities
                name="vendors"
                isMulti={true}
                disabled={disabled}
                type={ENTITY_TYPES.VENDORS}
                placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                label={(<strong> {translate('VENDORS$TITLE')} </strong>)}
                    />
        </Col> </Row>)}
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <SelectEntities
                    name="businessUnit"
                    disabled={disabled}
                    type={ENTITY_TYPES.BUSINESS_UNITS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    label={(<strong> {translate('BUSINESS_UNITS$BUSINESS_UNIT')} </strong>)}
                />
            </Col>
        </Row>
        <Accordion className="offset-bottom-8">
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Settings color="error" />&nbsp;<Typography component="strong" color="error"> {translate('SETTINGS$TITLE')} </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{display: 'block', width: '100%'}}>
                    <Row>
                        <Col xs={12} md={12} lg={4} className="offset-bottom-4">
                            <MdButton
                                onClick={resetPassword}
                                disabled={disabled||isNew}
                                className="md-btn md-btn-danger"
                                    >
                                {translate('FORGOT_PASSWORD$RESET_PASSWORD')}&nbsp;
                                <Preloader expectAnswer={disabled} type="ICON"> </Preloader>
                            </MdButton>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                            <Field
                                name="enabled"
                                color="primary"
                                fullWidth={false}
                                disabled={disabled}
                                component={MdSwitch}
                                label={(<strong> {translate('USERS$ENABLE_USER')} </strong>)}
                                    />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                            <Field
                                name="locked"
                                fullWidth={false}
                                disabled={disabled}
                                component={MdSwitch}
                                label={(<strong> {translate('USERS$LOCK_USER')} </strong>)}
                                    />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <Field
                                fullWidth={false}
                                disabled={disabled}
                                component={MdSwitch}
                                name="useMultiFactorAuth"
                                label={(<strong> Multi-factor authentication </strong>)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                type="date"
                                fullWidth={true}
                                disabled={disabled}
                                name="expirationDate"
                                component={MdDatePicker}
                                label={(<strong> {translate('USERS$EXPIRATION_DATE')} </strong>)}
                                helpText={(<code> {translate('USERS$EXPIRATION_DATE_HELP_TEXT')} </code>)}
                                    />
                        </Col>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                name="expired"
                                color="secondary"
                                fullWidth={false}
                                disabled={disabled}
                                component={MdSwitch}
                                label={(<strong> {translate('USERS$EXPIRED_USER')} </strong>)}
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                type="date"
                                fullWidth={true}
                                disabled={disabled}
                                component={MdDatePicker}
                                name="credentialsExpirationDate"
                                label={(<strong> {translate('USERS$CREDENTIALS_EXPIRATION_DATE')} </strong>)}
                                helpText={(<code> {translate('USERS$CREDENTIALS_EXPIRATION_DATE_HELP_TEXT')} </code>)}
                                    />
                        </Col>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                color="secondary"
                                fullWidth={false}
                                disabled={disabled}
                                component={MdSwitch}
                                name="credentialsExpired"
                                label={(<strong> {translate('USERS$EXPIRED_USER_CREDENTIALS')} </strong>)}
                                    />
                        </Col>
                    </Row>
                </div>
            </AccordionDetails>
        </Accordion>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_USERS_CREATE' : 'BUTTON_USERS_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_USERS_RESET')}/>
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_USERS_CANCEL')} />
            </Col>
        </Row>
    </form>)
})));
